.edit-textbox {
    margin: 30px;
    width: 100px;
}

.editform {
    // position: relative;
    display: flex;
    flex-wrap: wrap;
    padding: 10px;
}