/**
 * Stylesheet for general components
 */
.accessbility_hide {
  position: fixed;
  top: -10em;
}
.upload-video {
  margin-top: 1rem;
  width: 100%;
  height: 6rem;
  font-size: 1.5rem;}

.general-form {
  padding-left: 1rem;
  width: 100%;
  min-height: 10rem;
}

.end-padding {
  height: 5rem;
  width: 100%;
  position: relative;
}

.sidebar-dimmer {
  position: absolute;
  background: #4444445b;
  z-index: 100;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
}

/* .MuiPaper-rounded, .ui.selection.dropdown, .ui.selection.dropdown .menu, .ui.selection .menu, .button, .ui.message, .modal, .card, .modal input, .modal textarea, .ui.segment, .ui.segments .segment {
  border-radius: 0 !important;
} */

.ui.selection.active.dropdown, .ui.selection.active.dropdown .menu {
  border-color: #fafafa !important;
  box-shadow: 0 0 10px rgba(4, 32, 32, 0.15) !important;
}
.ui.selection.active.dropdown .menu::-webkit-scrollbar {
  display: block;
  width: 6px;
}

.ui.multiple.dropdown>.label {
  background: rgb(230, 234, 235);
  color: rgb(95, 95, 95);}
  .ui.multiple.dropdown>.label:hover {
    background: rgb(226, 236, 238);
  }
/* .ui.dropdown .menu {
  border-color: rgb(22, 189, 180);
}
.ui.selection.active.dropdown {
  border-color: rgb(22, 189, 180) !important;
} */


.alert-primary {
  background: #228686 !important;
  color: white !important;
  font-size: 1.2em;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.alert-primary p {
  margin-left: 1rem;
}

.ui.secondary.button {
  background: var(--ct-btn-color) !important;
}
.ui.primary.button {
  background: var(--ct-btn-color) !important;
}

.plain-btn {
  background: transparent;
  border: 0;
  padding: 0;
  margin: 0;
  outline: none;
  text-align: left;
}

ul.plain-ul {
  list-style-type: none; /* Remove bullets */
  padding: 0; /* Remove padding */
  margin: 0; /* Remove margins */
}

/* No result class */
.no-results {
  width: 100%;
  text-align: center;
  color: grey;
}

.no-results.left {
  text-align: start;
}

