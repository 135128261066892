
// INoteChapter 
.ct-epb.ch-img-con {
    position: relative;
    width: 100%;
    flex: 0 0 auto;
    padding-bottom: 20px;
    border-top: solid 1px rgb(236, 236, 236);
    .ch-img-wrapper{
        position: relative;
        min-width: 150px;
        height: max-content;

        .ct-epb.ch-img {
            display: block;
            margin-left: auto;
            margin-right: auto;
            position: relative;
            padding-left: 10px;
            padding-top: 10px;
            padding-bottom: 10px;
            width: 220px;
            object-fit: contain;
            cursor: zoom-in;
        }
    }
}

.item-text {
    position: relative;
    white-space: pre-wrap;
    padding-left: 10px;
    padding-top: 10px;
    font-size: 14px;
    line-height: 23px;
    // height: 92px;
    overflow: hidden;
}
// .ct-inote-chapter {
//     .item-actions {
//         width: 100%;
//         transition: opacity .2s ease-in;
//         opacity: 0;
//         pointer-events: none;
//         border-radius: 0 20px 20px 0;
//         padding-left: 20px;
//         // background-color: rgb(245, 245, 245);
//     }
// }