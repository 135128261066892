#ipl-pl-info {
  .pl-name {
    padding-left: 0;
    padding-bottom: 0;
    font-size: 21px;
  }

  .details {
    line-height: 20px;
    padding: 8px 15px;
    background-color: #eeeeee79;
    border-radius: 10px;
  }
}